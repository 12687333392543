// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-categories-js": () => import("./../src/pages/articles/categories.js" /* webpackChunkName: "component---src-pages-articles-categories-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-tags-js": () => import("./../src/pages/articles/tags.js" /* webpackChunkName: "component---src-pages-articles-tags-js" */),
  "component---src-pages-best-js": () => import("./../src/pages/best.js" /* webpackChunkName: "component---src-pages-best-js" */),
  "component---src-pages-blank-js": () => import("./../src/pages/blank.js" /* webpackChunkName: "component---src-pages-blank-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-courses-domain-driven-design-typescript-js": () => import("./../src/pages/courses/domain-driven-design-typescript.js" /* webpackChunkName: "component---src-pages-courses-domain-driven-design-typescript-js" */),
  "component---src-pages-courses-js": () => import("./../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-insta-index-js": () => import("./../src/pages/links/insta/index.js" /* webpackChunkName: "component---src-pages-links-insta-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-names-construct-structure-js": () => import("./../src/pages/resources/names-construct-structure.js" /* webpackChunkName: "component---src-pages-resources-names-construct-structure-js" */),
  "component---src-pages-resources-solid-nodejs-architecture-js": () => import("./../src/pages/resources/solid-nodejs-architecture.js" /* webpackChunkName: "component---src-pages-resources-solid-nodejs-architecture-js" */),
  "component---src-pages-uses-js": () => import("./../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-pages-wiki-js": () => import("./../src/pages/wiki.js" /* webpackChunkName: "component---src-pages-wiki-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-letter-js": () => import("./../src/templates/letter.js" /* webpackChunkName: "component---src-templates-letter-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-wiki-js": () => import("./../src/templates/wiki.js" /* webpackChunkName: "component---src-templates-wiki-js" */)
}

